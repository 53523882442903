import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { getFCP } from 'web-vitals';
import Timer from './CountdownTimer';
export function getNavigationTiming() {
  var _performance, _performance$timing;
  if (typeof ((_performance = performance) === null || _performance === void 0 ? void 0 : (_performance$timing = _performance.timing) === null || _performance$timing === void 0 ? void 0 : _performance$timing.responseStart) !== 'number') {
    // when we're on the server, there's no appropriate value for this so we'll just default to 0.
    // none of the metrics collected here should be published when running on the server
    return {
      responseStart: 0,
      fetchStart: 0
    };
  }
  // Performance Timing API has been deprecated and replaced with Navigation Timing Level 2,
  // but browsers like Firefox are populate quirky values when using this new API.
  // To simulate L2, values are normalized to start from the beginning of page load than epoch time
  return {
    responseStart: performance.timing.responseStart - performance.timing.navigationStart,
    fetchStart: performance.timing.fetchStart - performance.timing.navigationStart
  };
}
const getFileExtension = path => {
  const matches = path.match(/.+\.(\w+)$/);
  return matches === null || matches === void 0 ? void 0 : matches[1];
};
/**
 * The `initiatorType` property on the resource timing object indicates the type of
 * the source which initiated the resource load rather than type of resource being loaded.
 * While `initiatorType` provides a good approximation usually, for some cases this needs to
 * be normalized.
 * E.g. Image requests triggered from CSS files will have `initiatorType: css` instead of `img`.
 */
const getResourceType = resource => {
  const resourcePath = new URL(resource.name).pathname;
  const resourceFileExtension = getFileExtension(resourcePath);
  // Files from https://marketplace.com/files/... are more likely
  // to be images than CSS assets if initiated from CSS
  if (resource.initiatorType === 'css' && (resourcePath.startsWith('/files/') || resourcePath.startsWith('/product-listing/files/'))) {
    return resourceFileExtension || 'img';
  }
  if (resource.initiatorType === 'link' && resourceFileExtension) return resourceFileExtension;
  return resource.initiatorType;
};

/**
 * Returns the total time spend time transferring
 * matching requests, grouped by resource typ
 */
export function getNetworkRequestTimes(allowRegexes, denyRegexes) {
  const perfEntries = performance.getEntriesByType('resource');

  // 1. Filter all requests based on match criteria
  const matchedRequests = perfEntries.filter(entry => allowRegexes.some(regex => {
    var _entry$name$match;
    return (_entry$name$match = entry.name.match(regex)) === null || _entry$name$match === void 0 ? void 0 : _entry$name$match.length;
  })).filter(entry => denyRegexes.every(regex => {
    var _entry$name$match2;
    return !((_entry$name$match2 = entry.name.match(regex)) !== null && _entry$name$match2 !== void 0 && _entry$name$match2.length);
  })).map(entry => ({
    type: getResourceType(entry),
    name: entry.name,
    duration: entry.responseEnd - entry.startTime
  }));

  // 2. Sum up download times by resource type
  const requestTimeGroupedByType = matchedRequests.reduce((groupedByType, nextRequest) => {
    if (nextRequest.type in groupedByType) {
      groupedByType[nextRequest.type] += nextRequest.duration;
    } else {
      groupedByType[nextRequest.type] = nextRequest.duration;
    }
    return groupedByType;
  }, {});

  // 3. Return sum of times as an array
  return Object.entries(requestTimeGroupedByType).map(_ref => {
    let [type, duration] = _ref;
    return {
      type,
      duration
    };
  });
}

/**
 * Returns timestamp for First Contentful Paint (FCP) as measured by the browser
 * @see https://developer.mozilla.org/en-US/docs/Web/API/PerformancePaintTiming
 */
export function waitForFirstContentfulPaint() {
  return new Promise(resolve => {
    getFCP(_ref2 => {
      let {
        value
      } = _ref2;
      return resolve(value);
    });
  });
}

/**
 * Uses the experimental Long Tasks API to detect when the main
 * thread is consistently unblocked
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Long_Tasks_API
 */
export function waitForNoLongTasks() {
  let quiescenceFor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2000;
  return new Promise(resolve => {
    let lastLongRunningTaskTime = null;
    const timer = new Timer(quiescenceFor, () => {
      observer.disconnect();
      resolve(lastLongRunningTaskTime);
    });
    timer.start();
    const callback = list => {
      const perfEntries = list.getEntries();
      if (perfEntries.length) {
        const lastEntry = perfEntries[perfEntries.length - 1];
        lastLongRunningTaskTime = lastEntry.startTime + lastEntry.duration;
        timer.restart();
      }
    };
    const observer = new PerformanceObserver(callback);
    observer.observe({
      type: 'longtask',
      buffered: true
    });
  });
}

/**
 * Uses the MutationObserver API to detect when
 * there are no changes being made to the DOM subttree of the root node
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 */
export function waitForNoDOMMutations(rootNode) {
  let quiescenceFor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
  const mutationObserverConfig = {
    attributes: false,
    childList: true,
    subtree: true
  };
  return new Promise(resolve => {
    let lastMutationTime = null;
    const timer = new Timer(quiescenceFor, () => {
      observer.disconnect();
      resolve(lastMutationTime);
    });
    timer.start();
    const callback = () => {
      lastMutationTime = performance.now();
      timer.restart();
    };
    const observer = new MutationObserver(callback);
    observer.observe(rootNode, mutationObserverConfig);
  });
}

/**
 * We use a slightly simplified algorithm to the one mentioned here
 * to detect time to interactive
 * @see https://web.dev/tti/#what-is-tti
 *
 * TTI is the max of the following timestamps (measured from navigation start) –
 * 1. The first contentful paint (FCP)
 * 2. Timestamp (after FCP) after which no long running tasks have happened for at least 2 seconds
 * 3. Timestamp (after FCP) after which no DOM mutations have happened for at least 2 seconds
 */
export function waitForTimeToInteractive(rootNode) {
  return waitForFirstContentfulPaint().then(FCPTimestamp => Promise.all([waitForNoLongTasks(), waitForNoDOMMutations(rootNode)]).then(_ref3 => {
    let [mainTheadFreeTimestamp, DOMMutationFreeTimestamp] = _ref3;
    return Math.max(FCPTimestamp, mainTheadFreeTimestamp || 0, DOMMutationFreeTimestamp || 0);
  }));
}
export class PageVisibilityObserver {
  static start() {
    PageVisibilityObserver.hasDocumentBeenHidden = document.hidden;
    if (!PageVisibilityObserver.isObserving) {
      document.addEventListener('visibilitychange', PageVisibilityObserver.handleVisibilityChange, true);
      PageVisibilityObserver.isObserving = true;
    }
  }
  static handleVisibilityChange() {
    if (document.hidden) {
      PageVisibilityObserver.hasDocumentBeenHidden = true;
      document.removeEventListener('visibilitychange', PageVisibilityObserver.handleVisibilityChange, true);
    }
  }
}
_defineProperty(PageVisibilityObserver, "isObserving", false);
_defineProperty(PageVisibilityObserver, "hasDocumentBeenHidden", false);