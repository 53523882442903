import { ANALYTICS_SCREEN_APP_VERSION_TRANSITION, ANALYTICS_SCREEN_MANAGE_APP_VERSION_PUBLISH } from '../analytics/Sources';
export const appVersionPublishRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_PUBLISH,
  path: '/manage/apps/:appId(\\d+)/versions/create',
  isNestedRoute: false
};
export const appVersionPublishFormRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_PUBLISH,
  path: '/manage/apps/:appId(\\d+)/versions/create/form',
  isNestedRoute: false
};
export const appVersionTransitionRoute = {
  name: ANALYTICS_SCREEN_APP_VERSION_TRANSITION,
  path: '/manage/apps/:appId(\\d+)/versions/:buildNumber(\\d+)/transition',
  isNestedRoute: false
};