import { useQuery } from '@apollo/client';
import { SpinnerMask } from '@atlassian/amkt-common';
import { RedirectExternal, useParams } from '@atlassian/mpac-ui';
import invariant from 'invariant';
import * as React from 'react';

import NotFound from '../../../../../../../common/components/NotFound';
import SEOFriendlyRedirect from '../../../../../../../common/components/SEOFriendlyRedirect';
import { APP_LISTING_HOME_LINK } from '../../constants';
import { getRouteToRedirectTo } from './utils';

import type {
  GetMarketplacePluginByAppIdQuery,
  GetMarketplacePluginByAppIdQueryVariables,
  GetMarketplacePluginByAppKeyQuery,
  GetMarketplacePluginByAppKeyQueryVariables,
  GetMarketplacePluginIconByAppKeyQuery,
  GetMarketplacePluginIconByAppKeyQueryVariables,
} from './queries.gql.types';

import {
  GetMarketplacePluginByAppId,
  GetMarketplacePluginByAppKey,
  GetMarketplacePluginIconByAppKey,
} from './queries.graphql';

export const PluginsRedirectRoute = () => {
  const params = useParams();
  const { appKey, tab } = params;

  invariant(appKey, `parameter 'appKey' is required`);

  const { data, loading } = useQuery<
    GetMarketplacePluginByAppKeyQuery,
    GetMarketplacePluginByAppKeyQueryVariables
  >(GetMarketplacePluginByAppKey, {
    variables: { appKey },
  });

  if (loading) {
    return <SpinnerMask active delay={750} testId="plugin-redirect" />;
  }

  if (data?.marketplaceAppByKey?.appId) {
    return (
      <SEOFriendlyRedirect
        to={getRouteToRedirectTo({ app: data.marketplaceAppByKey, params: { appKey, tab } })}
        statusCode={301}
      />
    );
  }
  return <NotFound homeLink={APP_LISTING_HOME_LINK} />;
};

export const PluginDetailsRedirectRoute = () => {
  const params = useParams();
  const { appId } = params;

  invariant(appId, `parameter 'appId' is required`);

  const { data, loading } = useQuery<
    GetMarketplacePluginByAppIdQuery,
    GetMarketplacePluginByAppIdQueryVariables
  >(GetMarketplacePluginByAppId, {
    variables: { appId },
  });

  if (loading) {
    return <SpinnerMask active delay={750} testId="plugin-redirect" />;
  }

  if (data?.marketplaceApp?.appId) {
    return (
      <SEOFriendlyRedirect
        to={getRouteToRedirectTo({ app: data.marketplaceApp, params: { appId } })}
        statusCode={301}
      />
    );
  }
  return <NotFound homeLink={APP_LISTING_HOME_LINK} />;
};

export const PluginsIconRedirectRoute = () => {
  const params = useParams();
  const { appKey } = params;

  invariant(appKey, `parameter 'appKey' is required`);

  const { data, loading } = useQuery<
    GetMarketplacePluginIconByAppKeyQuery,
    GetMarketplacePluginIconByAppKeyQueryVariables
  >(GetMarketplacePluginIconByAppKey, {
    variables: { appKey },
  });

  if (loading) {
    return <SpinnerMask active delay={750} testId="plugin-redirect" />;
  }

  if (data?.marketplaceAppByKey?.appId) {
    return (
      <RedirectExternal
        statusCode={302}
        url={`/product-listing/files/${data.marketplaceAppByKey?.logo?.scaled?.id}`}
      />
    );
  }
  return <NotFound homeLink={APP_LISTING_HOME_LINK} />;
};
