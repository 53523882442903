import Telemetry from '@atlassian/mpac-telemetry';
import { useHistory, useLocation } from '@atlassian/mpac-ui';
import { useEffect } from 'react';

type Props = {
  getRouteName: (pathname: string, search?: string) => string | null | undefined;
};

function TelemetryNavigationListener({ getRouteName }: Props) {
  const { pathname, search } = useLocation();
  // @ts-expect-error [MC-2850] - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string | undefined'.
  const routeName = getRouteName(pathname, search) || 'unknown';
  Telemetry.setPage(routeName);
  const history = useHistory();
  // Trace page load event for all routes
  useEffect(() => {
    // Trace Page load on initial load
    Telemetry.traceUFOPageLoad(routeName);
    // @ts-expect-error [MC-2850] - TS2532 - Object is possibly 'undefined'.
    history.listen((location) => {
      // @ts-expect-error [MC-2850] - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      const pageName = getRouteName(location.pathname, location.search) || 'unknown';
      Telemetry.setPage(pageName);
      // Trace page transition
      Telemetry.traceUFOPageLoad(pageName);
    });
  }, []);
  return null;
}

export default TelemetryNavigationListener;
