import { MarketplaceAppPaymentModel, MarketplaceEntityStatus, MarketplaceListingStatus } from '@atlassian/mpac-types/client-graphql-schema-types';
import { DateUtils, mediaImageFromProductListingLink, mediaImageLink, PaymentModelUtils } from '@atlassian/mpac-utils';
const getMedia = (highlights, screenshots, statsigClient) => {
  const media = [];
  if (highlights) {
    media.push(...highlights.map(highlight => ({
      __typename: 'MarketplaceVersionHistoryMedia',
      id: highlight.screenshot.image.original.id,
      title: highlight.title,
      url: statsigClient.enableMediaFromProductListing() ? mediaImageFromProductListingLink().expand({
        imageId: highlight.screenshot.image.original.id
      }) : mediaImageLink().expand({
        imageId: highlight.screenshot.image.original.id
      })
    })));
  }
  if (screenshots) {
    media.push(...screenshots.map(screenshot => ({
      __typename: 'MarketplaceVersionHistoryMedia',
      id: screenshot.image.original.id,
      title: screenshot.caption,
      url: statsigClient.enableMediaFromProductListing() ? mediaImageFromProductListingLink().expand({
        imageId: screenshot.image.original.id
      }) : mediaImageLink().expand({
        imageId: screenshot.image.original.id
      })
    })));
  }
  return media;
};
const shouldShowTryBuy = options => {
  const {
    compatibleProduct,
    paymentModel,
    listingStatus,
    entityStatus
  } = options;
  return compatibleProduct.__typename === 'CompatibleAtlassianDataCenterProduct' && paymentModel === MarketplaceAppPaymentModel.PaidViaAtlassian && isAppPublicAndActive(listingStatus, entityStatus);
};
export const isAppPublicAndActive = (listingStatus, entityStatus) => listingStatus === MarketplaceListingStatus.Public && entityStatus === MarketplaceEntityStatus.Active;
const getTryUrl = (macBaseUrl, appKey, options) => {
  const {
    compatibleProduct
  } = options;
  return shouldShowTryBuy(options) ? "".concat(macBaseUrl, "/addon/try/").concat(appKey, ".").concat(getAppKeySuffix(compatibleProduct)) : null;
};
const getBuyUrl = (wacBaseUrl, appKey, options) => {
  const {
    compatibleProduct
  } = options;
  return shouldShowTryBuy(options) ? "".concat(wacBaseUrl, "/purchase/addon/").concat(appKey, ".").concat(getAppKeySuffix(compatibleProduct)) : null;
};
export const transposeVersions = (app, macBaseUrl, mpacBaseUrl, wacBaseUrl, statsigClient) => {
  var _versions$edges;
  const {
    versions,
    appId,
    appKey,
    partner,
    listingStatus,
    entityStatus
  } = app;
  const versionNodes = versions === null || versions === void 0 ? void 0 : (_versions$edges = versions.edges) === null || _versions$edges === void 0 ? void 0 : _versions$edges.map(edge => edge === null || edge === void 0 ? void 0 : edge.node).filter(Boolean);
  if (!versionNodes || versionNodes.length === 0) {
    throw new Error('No versions found');
  }
  return {
    __typename: 'MarketplaceVersionHistoryVersions',
    edges: versionNodes.map(node => {
      var _node$highlights, _node$screenshots, _node$releaseNotes;
      return {
        __typename: 'MarketplaceVersionHistoryVersionEdge',
        node: {
          __typename: 'MarketplaceVersionHistoryVersion',
          // Build numbers are a unique way to identify a version,
          // staging data has duplicate versions, but build numbers
          // are always unique across those same versions
          id: node.buildNumber,
          // Same version may be compatible with diff products
          // Currently the API may also return 'CLOUD', although we're passing a
          // productHostingOptions filter without CLOUD, such values shall be filtered here
          // Sample staging URL: https://marketplace.stg.internal.atlassian.com/apps/1221613/opsgenie-incident-timeline-lab/version-history?versionHistoryHosting=dataCenter
          compatibilities: node.deployment.compatibleProducts.map(getCompatibility).filter(Boolean),
          releaseDate: DateUtils.ISOFormatHyphenatedDate(node.releaseDate),
          releaseSummary: node.releaseSummary,
          version: node.version,
          media: getMedia((_node$highlights = node.highlights) !== null && _node$highlights !== void 0 ? _node$highlights : [], (_node$screenshots = node.screenshots) !== null && _node$screenshots !== void 0 ? _node$screenshots : [], statsigClient),
          releaseNotes: (_node$releaseNotes = node.releaseNotes) !== null && _node$releaseNotes !== void 0 ? _node$releaseNotes : 'No release notes',
          paymentModel: PaymentModelUtils.mapPaymentModelToDisplayValue(node.paymentModel),
          license: node.licenseType ? {
            type: node.licenseType.name,
            url: node.licenseType.link
          } : null,
          supportDetail: node.isSupported ? "Supported".concat(partner !== null && partner !== void 0 && partner.name ? " by ".concat(partner === null || partner === void 0 ? void 0 : partner.name) : '') : 'Not Supported',
          downloadUrl: isAppPublicAndActive(listingStatus, entityStatus) ? "".concat(mpacBaseUrl, "/download/apps/").concat(appId, "/version/").concat(node.buildNumber) : null,
          signatureUrl: isAppPublicAndActive(listingStatus, entityStatus) ? "".concat(mpacBaseUrl, "/download/app/").concat(app.appKey, "/").concat(node.buildNumber, "/signature") : null,
          tryUrl: getTryUrl(macBaseUrl, appKey, {
            compatibleProduct: node.deployment.compatibleProducts[0],
            paymentModel: node.paymentModel,
            listingStatus,
            entityStatus
          }),
          buyUrl: getBuyUrl(wacBaseUrl, appKey, {
            compatibleProduct: node.deployment.compatibleProducts[0],
            paymentModel: node.paymentModel,
            listingStatus,
            entityStatus
          })
        }
      };
    }),
    pageInfo: versions.pageInfo,
    totalCount: versions.totalCount
  };
};
const getAppKeySuffix = compatibleProduct => {
  switch (compatibleProduct.__typename) {
    case 'CompatibleAtlassianServerProduct':
      return 'server';
    case 'CompatibleAtlassianDataCenterProduct':
      return 'data-center';
    default:
      return '';
  }
};
const getCompatibility = compatibleProduct => {
  switch (compatibleProduct.__typename) {
    case 'CompatibleAtlassianServerProduct':
    case 'CompatibleAtlassianDataCenterProduct':
      {
        var _compatibleProduct$at;
        const product = (_compatibleProduct$at = compatibleProduct.atlassianProduct) === null || _compatibleProduct$at === void 0 ? void 0 : _compatibleProduct$at.name;
        const hosting = compatibleProduct.__typename === 'CompatibleAtlassianDataCenterProduct' ? 'Data Center' : 'Server';
        const minVersion = compatibleProduct.minimumVersion;
        const maxVersion = compatibleProduct.maximumVersion;
        const versionRange = minVersion === maxVersion ? minVersion : "".concat(minVersion, " - ").concat(maxVersion);
        return "".concat(product, " ").concat(hosting, " ").concat(versionRange);
      }
    default:
      return null;
  }
};
export const getVersionHistoryCacheKey = (appId, hostingFilter) => "".concat(appId).concat(hostingFilter ? "-".concat(hostingFilter) : '');
export const getHostingFilterValue = filters => {
  var _filters$versionHisto;
  if (!(filters !== null && filters !== void 0 && (_filters$versionHisto = filters.versionHistoryHosting) !== null && _filters$versionHisto !== void 0 && _filters$versionHisto[0])) {
    return null;
  }
  return filters.versionHistoryHosting[0].value;
};