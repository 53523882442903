import { RUNS_ON_ATLASSIAN_PROGRAM } from '@atlassian/mpac-types';
export const TrustFilters = () => {
  return [{
    label: 'Runs on Atlassian',
    id: 'runsOnAtlassian',
    value: RUNS_ON_ATLASSIAN_PROGRAM
  }, {
    label: 'Cloud Fortified',
    id: 'cloudFortified',
    value: 'approved'
  }, {
    label: 'Cloud Security Participant',
    id: 'bugBountyParticipant',
    value: 'bugBountyApproved'
  }];
};