import { matchPath } from 'react-router';

import type { AMKTRoute } from '../components/Routes.types';

export const partnerReportsScreensForUFOEvents = {
  sales: 'managePartnerReportsSalesScreen',
  evaluations: 'managePartnerReportsEvaluationsScreen',
  feedback: 'managePartnerReportsFeedbackScreen',
  conversions: 'managePartnerReportsCloudConversionsScreen',
  renewals: 'managePartnerReportsCloudRenewalsScreen',
  churn: 'managePartnerReportsCloudChurnScreen',
  licenses: 'managePartnerReportsLicensesScreen',
  transactions: 'managePartnerReportsTransactionsScreen',
  feedbackDetails: 'managePartnerReportsFeedbackDetailsScreen',
};

const overrideRouteNameMap = {
  appListingScreen: {
    '/apps/:appId(\\d+)/:appSlug([^/]+)\\?(.*&)?tab=pricing(&.*)?': 'appListingPricingScreen',
  },
  managePartnerReportsScreen: {
    '/manage/vendors/:partnerId(\\d+)/reporting/sales(\\?.*)?':
      partnerReportsScreensForUFOEvents.sales,
    '/manage/vendors/:partnerId(\\d+)/reporting/evaluations(\\?.*)?':
      partnerReportsScreensForUFOEvents.evaluations,
    '/manage/vendors/:partnerId(\\d+)/reporting/feedback(\\?.*)?':
      partnerReportsScreensForUFOEvents.feedback,
    '/manage/vendors/:partnerId(\\d+)/reporting/conversions(\\?.*)?':
      partnerReportsScreensForUFOEvents.conversions,
    '/manage/vendors/:partnerId(\\d+)/reporting/renewals(\\?.*)?':
      partnerReportsScreensForUFOEvents.renewals,
    '/manage/vendors/:partnerId(\\d+)/reporting/churn(\\?.*)?':
      partnerReportsScreensForUFOEvents.churn,
    '/manage/vendors/:partnerId(\\d+)/reporting/licenses(\\?.*)?':
      partnerReportsScreensForUFOEvents.licenses,
    '/manage/vendors/:partnerId(\\d+)/reporting/transactions(\\?.*)?':
      partnerReportsScreensForUFOEvents.transactions,
    '/manage/vendors/:partnerId(\\d+)/reporting/feedback-details(\\?.*)?':
      partnerReportsScreensForUFOEvents.feedbackDetails,
  },
};

export const resolveCurrentRoute = (
  allRoutes: AMKTRoute[],
  pathname: string
): AMKTRoute | null | undefined =>
  allRoutes.find((route) => matchPath(pathname, { path: route.path, exact: true, strict: false }));

export const getMatchedRoute = (allRoutes: AMKTRoute[], pathname: string) =>
  matchPath(pathname, {
    path: resolveCurrentRoute(allRoutes, pathname)?.path,
    exact: true,
    strict: false,
  });

export const getRouteByName = (allRoutes: AMKTRoute[], routeName: string) =>
  allRoutes.find(({ name }) => name === routeName);

/** This function returns the route name for the given pathname.
It also checks if the route name has an override pattern and returns the overridden route name if the pathname matches the override pattern.
*/
export const getRouteNameForTelemetry = (
  allRoutes: AMKTRoute[],
  pathname: string,
  search?: string
): string | null | undefined => {
  const routeObj = resolveCurrentRoute(allRoutes, pathname);
  if (routeObj?.name && overrideRouteNameMap[routeObj.name]) {
    const overridePattern = Object.keys(overrideRouteNameMap[routeObj.name]).find((pattern) =>
      matchPath(pathname.concat(search ?? ''), { path: pattern, exact: true })
    );
    return overridePattern ? overrideRouteNameMap[routeObj.name][overridePattern] : routeObj.name;
  }
  return routeObj?.name;
};
