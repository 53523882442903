import {
  allManageAppsRoutes,
  allManagePartnersRoutes,
  appVersionPublishFormRoute,
  appVersionPublishRoute,
  appVersionTransitionRoute,
  manageAppsBaseRoute,
  managePartnersBaseRoute,
} from '@atlassian/mpac-partners';
import { AtlassianSupportsUkraineRouteHandler } from '@atlassian/mpac-primitives';
import React from 'react';

import NotFound from '../../../common/components/NotFound';
import NotFoundPage from '../pages/errors/NotFoundPage';
import PlansPageRouteHandler from '../pages/plans/PlansPageRouteHandler';
import VersionHistoryPageRouteHandler from '../pages/versionHistory/VersionHistoryPageRouteHandler';
import resolveAddonDiscoveryRoutes from './components/AddonDiscoveryRoutes/components/AddonDiscoveryRoutes';
import resolveAppListingRoutes from './components/AppListingRoutes/components/AppListingRoutes';
import SolutionsRouteHandler from './components/CategoriesRoutes/components/SolutionsRouteHandler';
import resolveCollectionsRoutes from './components/CollectionsRoutes/routes/CollectionsRoutes';
import {
  ManageAppsRouteHandler,
  ManagePartnersRouteHandler,
  PublishAppVersionRoutesHandler,
} from './components/PartnerRoutes';
import resolveVendorDetailsRoutes from './components/VendorDetailsRoutes/components/VendorDetailsRoutes';
import { getAetherRoutes } from './getAetherRoutes';

import type AddonDiscoveryConfig from '../../records/AddonDiscoveryConfig';
import type CollectionsConfig from '../../records/CollectionsConfig';
import type SolutionsConfig from '../../records/SolutionsConfig';
import type VendorDetailsConfig from '../../records/VendorDetailsConfig';
import type { AMKTRoute } from './components/Routes.types';

type GetAllRoutesArgs = {
  addonDiscoveryConfig: AddonDiscoveryConfig;
  collectionsConfig: CollectionsConfig;
  vendorDetailsConfig: VendorDetailsConfig;
  solutionsConfig: SolutionsConfig;
  flags: {
    enableAetherExperienceFlag: boolean;
    enableMosaicPartnerProfileExperienceFlag: boolean;
  };
};

export default function getAllRoutes({
  addonDiscoveryConfig,
  collectionsConfig,
  vendorDetailsConfig,
  flags: { enableAetherExperienceFlag, enableMosaicPartnerProfileExperienceFlag },
}: GetAllRoutesArgs): AMKTRoute[] {
  let routes: AMKTRoute[] = [];

  if (enableAetherExperienceFlag) {
    routes = getAetherRoutes();
  } else {
    routes = [
      ...resolveAddonDiscoveryRoutes({
        addonDiscoveryConfig,
      }),
      ...resolveCollectionsRoutes({
        collectionsConfig,
      }),
      {
        name: 'categoriesScreen',
        path: '/categories/:categoryId',
        exact: true,
        component: SolutionsRouteHandler,
      },
    ];
  }
  return [
    ...routes,
    ...resolveVendorDetailsRoutes({
      vendorDetailsConfig,
      enableMosaicPartnerProfileExperienceFlag,
      enableAetherExperienceFlag,
    }),
    ...resolveAppListingRoutes({ enableAetherExperienceFlag }),
    {
      name: 'managePartnersScreen',
      path: managePartnersBaseRoute,
      component: ManagePartnersRouteHandler,
    },
    {
      name: 'ukraineSupportScreen',
      path: '/atlassian-stands-with-ukraine',
      exact: true,
      component: AtlassianSupportsUkraineRouteHandler,
    },
    {
      name: 'publishPrivateAppVersion',
      path: appVersionPublishRoute.path,
      component: PublishAppVersionRoutesHandler,
      exact: true,
    },
    {
      name: 'publishPrivateAppVersionForm',
      path: appVersionPublishFormRoute.path,
      component: PublishAppVersionRoutesHandler,
      exact: true,
    },
    {
      name: 'transitionAppVersion',
      path: appVersionTransitionRoute.path,
      component: PublishAppVersionRoutesHandler,
      exact: true,
    },
    {
      name: 'manageAppsScreen',
      path: manageAppsBaseRoute,
      component: ManageAppsRouteHandler,
    },
    {
      name: 'versionHistoryScreen',
      path: '/apps/:appId(\\d+)/:appSlug([^/]+)?/version-history',
      component: VersionHistoryPageRouteHandler,
      showAetherNavAndFooter: enableAetherExperienceFlag,
    },
    {
      name: 'plansScreen',
      path: '/apps/:appId(\\d+)/:appSlug([^/]+)?/plans',
      component: PlansPageRouteHandler,
      showAetherNavAndFooter: false,
    },
    {
      name: 'notFoundScreen',
      // eslint-disable-next-line react/display-name
      render: (routeProps) => {
        return enableAetherExperienceFlag ? (
          <NotFoundPage {...routeProps} />
        ) : (
          <NotFound {...routeProps} homeLink={addonDiscoveryConfig.links.home} />
        );
      },
      showAetherNavAndFooter: enableAetherExperienceFlag,
    },
    ...allManagePartnersRoutes,
    ...allManageAppsRoutes,
  ];
}
