import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { RUNS_ON_ATLASSIAN_PROGRAM } from '@atlassian/mpac-types';
export const transposeHighlightResult = highlightResult => {
  var _highlightResult$name, _highlightResult$tagL, _highlightResult$tagL2, _highlightResult$toke;
  return {
    __typename: '_HighlightResult',
    name: {
      __typename: 'HighlightResult',
      matchLevel: (highlightResult === null || highlightResult === void 0 ? void 0 : (_highlightResult$name = highlightResult.name) === null || _highlightResult$name === void 0 ? void 0 : _highlightResult$name.matchLevel) || '',
      value: (highlightResult === null || highlightResult === void 0 ? void 0 : highlightResult.name.value) || ''
    },
    tokenizedName: (highlightResult === null || highlightResult === void 0 ? void 0 : highlightResult.tokenizedName.map(item => ({
      __typename: 'HighlightResult',
      matchLevel: (item === null || item === void 0 ? void 0 : item.matchLevel) || '',
      value: item.value || ''
    }))) || [],
    tagLine: {
      __typename: 'HighlightResult',
      matchLevel: (highlightResult === null || highlightResult === void 0 ? void 0 : (_highlightResult$tagL = highlightResult.tagLine) === null || _highlightResult$tagL === void 0 ? void 0 : _highlightResult$tagL.matchLevel) || '',
      value: (highlightResult === null || highlightResult === void 0 ? void 0 : (_highlightResult$tagL2 = highlightResult.tagLine) === null || _highlightResult$tagL2 === void 0 ? void 0 : _highlightResult$tagL2.value) || ''
    },
    tokenizedTagLine: (highlightResult === null || highlightResult === void 0 ? void 0 : (_highlightResult$toke = highlightResult.tokenizedTagLine) === null || _highlightResult$toke === void 0 ? void 0 : _highlightResult$toke.map(item => ({
      __typename: 'HighlightResult',
      matchLevel: (item === null || item === void 0 ? void 0 : item.matchLevel) || '',
      value: item.value || ''
    }))) || []
  };
};
export const transposeAlgoliaHit = _ref => {
  var _hit$tags, _hit$programs, _hit$programs$cloudFo, _hit$programs2, _hit$programs2$bugBou, _hit$programs2$bugBou2, _hit$programs3, _hit$programs3$bugBou, _hit$programs3$bugBou2, _hit$programs4, _hit$programs4$bugBou, _hit$programs4$bugBou2, _hit$tags2, _hit$tags2$marketingL, _hit$vendor, _hit$vendor2, _hit$vendor3, _hit$vendor3$partnerT;
  let {
    hit,
    query = ''
  } = _ref;
  return {
    __typename: 'AppTileEdge',
    node: _objectSpread(_objectSpread({
      id: hit.addonId.toString() + query,
      __typename: 'AppTile'
    }, hit), {}, {
      logo: _objectSpread({
        __typename: 'Logo',
        highRes: hit.logo.highRes || ''
      }, hit.logo),
      releaseDate: hit.releaseDate || null,
      tags: {
        __typename: 'MarketingTags',
        marketingLabels: ((_hit$tags = hit.tags) === null || _hit$tags === void 0 ? void 0 : _hit$tags.marketingLabels) || []
      },
      ratings: _objectSpread({
        __typename: 'Ratings'
      }, hit.ratings),
      distribution: {
        __typename: 'Distribution',
        activeInstalls: hit.distribution.activeInstalls || 0,
        downloads: hit.distribution.downloads,
        bundled: hit.distribution.bundled
      },
      programs: {
        __typename: 'Programs',
        cloudFortified: {
          __typename: 'CloudFortified',
          status: ((_hit$programs = hit.programs) === null || _hit$programs === void 0 ? void 0 : (_hit$programs$cloudFo = _hit$programs.cloudFortified) === null || _hit$programs$cloudFo === void 0 ? void 0 : _hit$programs$cloudFo.status) || ''
        },
        bugBountyParticipant: {
          __typename: 'BugBountyParticipant',
          cloud: {
            __typename: 'BugBountyStatus',
            status: ((_hit$programs2 = hit.programs) === null || _hit$programs2 === void 0 ? void 0 : (_hit$programs2$bugBou = _hit$programs2.bugBountyParticipant) === null || _hit$programs2$bugBou === void 0 ? void 0 : (_hit$programs2$bugBou2 = _hit$programs2$bugBou.cloud) === null || _hit$programs2$bugBou2 === void 0 ? void 0 : _hit$programs2$bugBou2.status) || ''
          },
          server: {
            __typename: 'BugBountyStatus',
            status: ((_hit$programs3 = hit.programs) === null || _hit$programs3 === void 0 ? void 0 : (_hit$programs3$bugBou = _hit$programs3.bugBountyParticipant) === null || _hit$programs3$bugBou === void 0 ? void 0 : (_hit$programs3$bugBou2 = _hit$programs3$bugBou.server) === null || _hit$programs3$bugBou2 === void 0 ? void 0 : _hit$programs3$bugBou2.status) || ''
          },
          dataCenter: {
            __typename: 'BugBountyStatus',
            status: ((_hit$programs4 = hit.programs) === null || _hit$programs4 === void 0 ? void 0 : (_hit$programs4$bugBou = _hit$programs4.bugBountyParticipant) === null || _hit$programs4$bugBou === void 0 ? void 0 : (_hit$programs4$bugBou2 = _hit$programs4$bugBou.dataCenter) === null || _hit$programs4$bugBou2 === void 0 ? void 0 : _hit$programs4$bugBou2.status) || ''
          }
        },
        runsOnAtlassian: {
          __typename: 'RunsOnAtlassian',
          status: ((_hit$tags2 = hit.tags) === null || _hit$tags2 === void 0 ? void 0 : (_hit$tags2$marketingL = _hit$tags2.marketingLabels) === null || _hit$tags2$marketingL === void 0 ? void 0 : _hit$tags2$marketingL.includes(RUNS_ON_ATLASSIAN_PROGRAM)) || false
        }
      },
      vendor: {
        __typename: 'Vendor',
        id: (_hit$vendor = hit.vendor) === null || _hit$vendor === void 0 ? void 0 : _hit$vendor.id,
        name: (_hit$vendor2 = hit.vendor) === null || _hit$vendor2 === void 0 ? void 0 : _hit$vendor2.name,
        partnerTier: {
          __typename: 'PartnerTier',
          type: ((_hit$vendor3 = hit.vendor) === null || _hit$vendor3 === void 0 ? void 0 : (_hit$vendor3$partnerT = _hit$vendor3.partnerTier) === null || _hit$vendor3$partnerT === void 0 ? void 0 : _hit$vendor3$partnerT.type) || ''
        }
      },
      _highlightResult: transposeHighlightResult(hit._highlightResult)
    })
  };
};
export const transposeAppsConnection = _ref2 => {
  let {
    customResponse,
    query
  } = _ref2;
  const hits = customResponse === null || customResponse === void 0 ? void 0 : customResponse.results[0].hits;
  const currentPage = (customResponse === null || customResponse === void 0 ? void 0 : customResponse.results[0].page) || 0;
  const totalPages = (customResponse === null || customResponse === void 0 ? void 0 : customResponse.results[0].nbPages) || 0;
  const totalHits = (customResponse === null || customResponse === void 0 ? void 0 : customResponse.results[0].nbHits) || 0;
  return {
    __typename: 'AppsConnection',
    pageInfo: {
      __typename: 'AppConnectionPageInfo',
      totalCount: totalHits,
      hasNextPage: currentPage + 1 < totalPages
    },
    edges: hits === null || hits === void 0 ? void 0 : hits.map(hit => transposeAlgoliaHit({
      hit,
      query
    }))
  };
};