import { MaintenanceNone } from '@atlassian/mpac-types';
/* Default values for each of the feature flags from `MarketplaceFeatureFlags`. */
export const DEFAULT_MARKETPLACE_FEATURE_FLAGS = {
  replaceCategoriesOnAppTilesWithVendorName: false,
  enableBugBountyProgram: false,
  showCustomBackendErrorsFromUpm: false,
  enableFreeStarterTierFilter: false,
  disableAllHostingFromPromotionsDropdown: false,
  showQualtricsSurvey: false,
  enableRetryForLicenseDetailsPostInstallation: false,
  allowSectionMessageForPartnersForMigrationToCcp: [],
  enableReactivateForgeAppFromEmcee: false,
  enableReactivateForgeAppFromEmceeForHAMS: false,
  enableDualLicenseToggleInPartnersTable: false,
  enablePrefixCodeSectionForGenericCloudPromotions: false,
  enableBacRedirectionForCcpPromotions: false,
  enableFiftyKPricingTiers: false,
  useAnalyticsWebClient: false,
  enableDeprecatedInstallationsGQL: false,
  enableV2SearchIndex: false,
  maintenanceBannerConfiguration: MaintenanceNone,
  enablecapabilitysets: []
};